import { template as template_a32b28bc20374ff68187ffcaf09491e7 } from "@ember/template-compiler";
const FKLabel = template_a32b28bc20374ff68187ffcaf09491e7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
