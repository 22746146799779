import { template as template_111b1f2aa6b34af5adf7d0eb1eed496f } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
import SortableColumn from "./sortable-column";
const PostersCell = template_111b1f2aa6b34af5adf7d0eb1eed496f(`
  <SortableColumn
    @order="posters"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="posters"
    @screenreaderOnly={{true}}
    aria-label={{i18n "category.sort_options.posters"}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostersCell;
